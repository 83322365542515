<template>
  <div class="settings content">
      <v-container class="container">
        <settings-bar @tab="setTab"/>
        <div class="right">
            <profil v-if="active == 'profil'" />
            <organizations v-if="active == 'orgas'" />
            <campaigns v-if="active == 'campaigns'" />
            <orga-settings v-if="active == 'orgaSettings'" />
            <orga-user v-if="active == 'orgaUser'" />
            <campaign-settings v-if="active == 'campaignSettings'"/>
            <campaign-user v-if="active == 'campaignUser'"/>
        </div>
        </v-container>
  </div>
</template>
<script>
import Organizations from '../components/settings/Organizations.vue';
import OrgaSettings from '../components/settings/OrgaSettings.vue';
import Profil from '../components/settings/Profil.vue';
import OrgaUser from '../components/settings/OrgaUser.vue';
import SettingsBar from '../components/settings/SettingsBarVuetify.vue';
import Campaigns from '../components/settings/Campaigns.vue';
import CampaignSettings from '../components/settings/CampaignSettings.vue';
import CampaignUser from '../components/settings/CampaignUser.vue';
export default {
  components: { SettingsBar, Profil, Organizations, OrgaSettings, OrgaUser, Campaigns, CampaignSettings, CampaignUser },
  name: "Settings",
  data() {
    return {
      active: "profil"
    };
  },
  methods: {
    setTab(active){
        this.active = active;
    }
  },
  created: function(){
    let settingsState = this.$store.getters['settingsState'];
    this.active = settingsState;
  },
};
</script>
<style lang="less" scoped>
.settings {
    background: rgba(240, 240, 240, 0.6);

  .container {
    width: clamp(400px, 90%, 1500px);
    height: 80%;
    background-color: #eff0f1;
    margin: 2rem auto 2rem auto;
    box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.27);
    border-radius: 5px;
    display: flex;
    .right{
        flex:1;
        height: 100%;
        transition: width 0.3s ease-in-out;
        border-radius: 0 20px 20px 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .settings{

  .container{
    width: 100%;
    margin-top:0;
    padding: 0.5rem;
  }
  } 
}
</style>