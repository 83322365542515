<template>
  <div class="ma-12 pa-12">
    <v-navigation-drawer permanent :mini-variant.sync="mini" class="nav">
      <v-list nav dense>
        <v-list-item>
            <v-list-item-icon/>
          <v-list-item-title class="headers">Benutzer</v-list-item-title>
        </v-list-item>
        <v-divider/>
        <v-list-item link @click="acvtivateEmit('profil')">
          <v-list-item-icon>
            <v-icon>mdi-account-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Profil</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="acvtivateEmit('orgas')">
          <v-list-item-icon>
            <v-icon>mdi-domain</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Meine Organisationen</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="acvtivateEmit('campaigns')">
          <v-list-item-icon>
            <v-icon>mdi-account-box-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Meine Kampagnen</v-list-item-title>
        </v-list-item>
        <v-list-item>
            <v-list-item-icon/>
          <v-list-item-title class="headers">Organisation</v-list-item-title>
        </v-list-item>
        <v-divider/>
        <v-list-item link @click="acvtivateEmit('orgaSettings')">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Einstellungen</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="acvtivateEmit('orgaUser')">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Benutzerverwaltung</v-list-item-title>
        </v-list-item>
        <!--<v-list-item link @click="acvtivateEmit('orgaPayment')">
          <v-list-item-icon>
            <v-icon>mdi-credit-card-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Bezahlung</v-list-item-title>
        </v-list-item>-->
        <div v-if="selectedCampaign.campaign">
          <v-list-item>
            <v-list-item-icon/>
          <v-list-item-title class="headers">Kampagne</v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item link @click="acvtivateEmit('campaignSettings')">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Einstellungen</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="acvtivateEmit('campaignUser')">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Benutzerverwaltung</v-list-item-title>
          </v-list-item>
        </div>
        
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "SettingsBar",
  data() {
    return {
      mini:false,
    };
  },
  computed:{
    selectedCampaign(){
      return this.$store.getters['orga/campaign/selectedCampaign'];
    },
  },
  methods: {
    acvtivateEmit(emit) {
      this.mini = !this.mini;
      this.$emit("tab", emit);
    },
  },
};
</script>

<style lang="less" scoped>
.nav {
  border-radius: 5px;
  .headers{
      font-weight:600;
      font-size:18px;
      padding:0.5rem;
  }
}
</style>
