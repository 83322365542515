<template>
  <div class="profil">
    <v-card class="card">
      <v-card-title>Profil</v-card-title>
      <v-card-subtitle
        >Hier kannst du deine Profildaten einsehen</v-card-subtitle
      >
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <!-- <v-card class="card-in-card">
              <v-card-title>{{user.firstName + " " + user.lastName}}</v-card-title
              >
              <v-card-subtitle>Name</v-card-subtitle>
              <v-card-text v-if="change === true">
                <v-form @submit.prevent="changeFirstName" lazy-validation>
                   <v-row>
                  <v-col cols="8" md="4">
                      <div class="form-group" :class="{ 'form-group--error': $v.firstname.$error }">
                          <v-text-field v-model="firstname" v-bind:label="user.firstName" :error-messages="firstnameError"></v-text-field>
                      </div>
                  </v-col>
                  <v-col cols="8" md="4">
                    <v-btn type="submit" :loading="loadingFirstName">
                      Speichern
                    </v-btn>
                  </v-col>
                  </v-row>
                </v-form>
                
                <v-form @submit.prevent="changeLastName" lazy-validation>
                  <v-row>
                    <v-col cols="8" md="4">
                      <div class="form-group" :class="{ 'form-group--error': $v.lastname.$error }">
                          <v-text-field v-model="lastname" v-bind:label="user.lastName" :error-messages="lastnameError"></v-text-field>
                      </div>
                  </v-col>
                  <v-col cols="8" md="4">
                    <v-btn type="submit" :loading="loadingLastName">
                      Speichern
                    </v-btn>
                  </v-col>
                  </v-row> 
                </v-form>    
              </v-card-text>
            </v-card> -->
          </v-col>
          <v-col cols="12">
            <v-card class="card-in-card">
              <v-card-title>{{ user.nickname }}</v-card-title>
              <v-card-subtitle>Nutzername</v-card-subtitle>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-row class="actions">
              <!-- <v-btn text @click="change = !change">
              Daten bearbeiten
            </v-btn> -->
              <v-btn v-if="userSignInMethod == 1" text @click="changePassword">
                Passwort ändern
              </v-btn>
              <v-btn text @click="deleteUser"> Account Löschen </v-btn>
            </v-row>
          </v-card-actions>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { SignInMethod } from "@/store/modules/user.js";
export default {
  name: "Profil",
  data() {
    return {
      change: false,
      firstname: "",
      lastname: "",
      loadingFirstName: false,
      loadingLastName: false,
    };
  },
  validations: {
    firstname: {
      required,
    },
    lastname: {
      required,
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    userSignInMethod() {
      return this.$store.getters["user/userSignInMethod"];
    },
    /*firstnameError(){
        const errors = []
        if (!this.$v.firstname.$dirty)
            return errors
        !this.$v.firstname.required && errors.push('Bitte gib einen Vornamen an')
        return errors
    },
    lastnameError(){
        const errors = []
        if (!this.$v.lastname.$dirty)
            return errors
        !this.$v.lastname.required && errors.push('Bitte gib einen Nachnamen an')
        return errors
    }*/
  },
  methods: {
    /*async changeFirstName() {
        this.$v.firstname.$touch();
        if(!this.$v.firstname.$invalid){
            this.loadingFirstName = true;
            let user = Object.assign({}, this.user)
            user.firstName = this.firstname;
            this.$store.dispatch('user/updateUserData', user).then((res) => {
                if(res === -1){
                    console.error("Error updating Userdata!");
                }else{
                    this.$v.$reset(); 
                    this.changeName = false;
                    this.firstname = '';
                    this.lastname = '';
                }
                this.loadingFirstName = false;   
            });  
        }
    },
    async changeLastName(){
      this.$v.lastname.$touch();
        if(!this.$v.lastname.$invalid){
            this.loadingLastName = true;
            let user = Object.assign({}, this.user)
            user.lastName = this.lastname;
            this.$store.dispatch('user/updateUserData', user).then((res) => {
                if(res === -1){
                    console.error("Error updating Userdata!");
                }else{
                    this.$v.$reset();  
                    this.changeName = false;
                    this.firstname = '';
                    this.lastname = '';
                }
                this.loadingLastName = false;
            });
        }
    },*/
    async changePassword() {
      await this.$swal({
        title: "Passwort ändern",
        html: `<input type="password" id="oldPw" class="swal2-input" placeholder="Altes Passwort">
          <input type="password" id="pw" class="swal2-input" placeholder="Passwort">
          <input type="password" id="pw2" class="swal2-input" placeholder="Passwort wiederholen">`,
        showCancelButton: true,
        confirmButtonText: "Ändern",
        customClass: {
          confirmButton: "btn btn-confirm",
          cancelButton: "btn btn-cancel",
        },
        buttonsStyling: false,
        cancelButtonText: "Abbrechen",
        focusConfirm: false,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          let oldPw = document.getElementById("oldPw").value;
          let pw = document.getElementById("pw").value;
          let pw2 = document.getElementById("pw2").value;
          if (oldPw.length >= 6) {
            if (pw.length < 6) {
              this.$swal.showValidationMessage(
                "Das Passwort muss min. 6 Stellen haben"
              );
            } else if (pw != pw2) {
              this.$swal.showValidationMessage(
                "Die Passwörter stimmen nicht überein"
              );
            } else if (pw.length >= 6 && pw == pw2) {
              const pwObject = {
                oldPassword: oldPw,
                newPassword: pw,
              };
              return this.$store
                .dispatch("user/changePassword", pwObject)
                .then((res) => {
                  if (res == -1) {
                    this.$swal.showValidationMessage(
                      "Es ist ein Fehler aufgetreten"
                    );
                  } else if (res == 0) {
                    this.$swal.showValidationMessage(
                      "Du hast ein falsches Passwort eingegeben"
                    );
                  }
                });
            }
          } else {
            this.$swal.showValidationMessage(
              "Bitte gib dein altes Passwort ein"
            );
          }
        },
      }).then((res) => {
        if (res.isConfirmed) {
          this.$swal({
            title: "Dein Passwort wurde erfolgreich angepasst",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
        }
      });
    },
    async deleteUser() {
      let inputLabel;
      let input;
      if (this.userSignInMethod == SignInMethod.Email) {
        inputLabel = "Bitte gib dein Passwort ein:";
        input = "password";
      } else {
        inputLabel = "";
        input = false;
      }
      this.$swal({
        title: "Nutzer wirklich löschen?",
        icon: "warning",
        input: input,
        inputLabel: inputLabel,
        showCancelButton: true,
        confirmButtonText: "Löschen",
        customClass: {
          confirmButton: "btn btn-confirm",
          cancelButton: "btn btn-cancel",
        },
        buttonsStyling: false,
        cancelButtonText: "Abbrechen",
        showLoaderOnConfirm: true,
        inputValidator: (input) => {
          return new Promise((resolve) => {
            if (input.length < 6) {
              resolve("Bitte gib ein Passwort ein, dieses hat min. 6 Stellen");
            } else {
              resolve();
            }
          });
        },
        preConfirm: (input) => {
          return this.$store.dispatch("user/deleteUser", input).then((res) => {
            if (res == -1) {
              this.$swal.showValidationMessage("Es ist ein Fehler aufgetreten");
            }
          });
        },
      }).then((res) => {
        if (res.isConfirmed) {
          this.$swal({
            title: "Du hast deinen Account erfolgreich gelöscht",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  text-align: left;
  .card-in-card {
    border-radius: 5px;
    .error--text {
      color: rgb(255, 0, 0) !important;
    }
  }
  .actions {
    padding: 10px;
  }
}
</style>
