<template>
  <div class="planned-plakat">
      <v-container>
        <p>{{plannedPlakat.plakat.address.fullName}}</p>
        <v-text-field v-model="description" v-bind:label="this.descriptionLabel"></v-text-field>
        <v-btn text @click="changeDescription">
            Beschreibung sichern
        </v-btn>
        <v-btn text @click="deletePlakat">
            Plakat löschen
        </v-btn>
        <v-btn text @click="close">
            Schliessen
        </v-btn>
      </v-container>
  </div>
</template>

<script>
export default {
  name: "PlannedPlakat",
  data() {
    return {
        description: '',
        descriptionLabel: '',
    };
  },
  updated: function(){
      this.plannedPlakat.plakat.description == '' ? this.descriptionLabel = 'Beschreibung'
       : this.descriptionLabel = this.plannedPlakat.plakat.description;
  },
  created: function(){
      this.plannedPlakat.plakat.description == '' ? this.descriptionLabel = 'Beschreibung'
       : this.descriptionLabel = this.plannedPlakat.plakat.description;
  },
  props: ["plannedPlakat"],
  methods: {
      deletePlakat(){
          this.$emit('delete', this.plannedPlakat);
      },
      changeDescription(){
          this.plannedPlakat.plakat.description = this.description;
          this.$emit('description', this.plannedPlakat);
          this.description = '';
      },
      close(){
        this.$emit('close', null, null);
      },
  },
};
</script>

<style lang="less" scoped>
.planned-plakat {
  position: absolute;
  z-index: 1000;
  bottom: 50px;
  left: calc(20vw);
  width: 40vw;
  height: 200px;
  background: white;
  border-radius: 20px;
  box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.27);
  opacity: 1;
}

</style>