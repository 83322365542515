<template>
  <div class="orgas">
    <v-card class="card">
      <v-card-title>Kampagnen-Einstellungen</v-card-title>
      <v-card-subtitle
        >Hier kannst du die Daten deiner aktuell gewählten Kampagne
        einsehen</v-card-subtitle
      >
      <v-card-text>
        Diese Kampagne ist aktiv bis:
        <span class="selCampaign">{{ campaignEndDate }}</span> <br />
      </v-card-text>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-card class="card-in-card">
              <v-card-title>{{ selectedCampaign.campaign.name }}</v-card-title>
              <v-card-subtitle> Name </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="card-in-card">
              <v-card-title>{{
                selectedCampaign.campaign.manager
              }}</v-card-title>
              <v-card-subtitle>Kampagnen-Manager</v-card-subtitle>
            </v-card>
          </v-col>
          <!-- Aktuell nach Firebase-Rules nicht möglich Kampagnen zu löschen
            <v-card-actions >
            <v-row class="actions">
              <v-btn text v-if="user.nickname === selectedCampaign.campaign.manager" @click="deleteCampaign">
                Kampagne Löschen
              </v-btn>
            </v-row>
          </v-card-actions> -->
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "CampaignSettings",
  data() {
    return {};
  },
  computed: {
    selectedCampaign() {
      return this.$store.getters["orga/campaign/selectedCampaign"];
    },
    campaignInfo() {
      return this.$store.getters["orga/campaign/campaignInfo"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    campaignEndDate() {
      let dateFirebase = this.campaignInfo.campaignEndDate.toDate();
      let date = new Date(dateFirebase).toLocaleString("de-DE");
      return date;
    },
  },
  methods: {
    async deleteCampaign() {
      this.$swal({
        title: "Möchtest du die Kampagne wirklich löschen?",
        icon: "warning",
        input: "text",
        inputLabel: "Bitte gib den Namen der Kampagne ein:",
        showCancelButton: true,
        confirmButtonText: "Löschen",
        customClass: {
          confirmButton: "btn btn-confirm",
          cancelButton: "btn btn-cancel",
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        inputValidator: (input) => {
          return new Promise((resolve) => {
            if (input === this.selectedCampaign.campaign.name) {
              if (this.selectedCampaign.campaign.id != "DEMOCAMPAIGN") {
                resolve();
              } else {
                resolve("Die Testversion kann nicht gelöscht werden.");
              }
            } else {
              resolve("Du hast einen falschen Namen eingegeben!");
            }
          });
        },
        preConfirm: () => {
          return this.$store
            .dispatch("orga/campaign/deleteCampaign")
            .then((res) => {
              if (res == -1) {
                this.$swal.showValidationMessage(
                  "Es ist ein Fehler aufgetreten"
                );
              }
            });
        },
      }).then((res) => {
        if (res.isConfirmed) {
          this.$swal({
            title: "Die Kampagne wurde gelöscht",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText:"OK",
            buttonsStyling: false,
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  text-align: left;
  .selCampaign {
    font-weight: 650;
  }
  .card-in-card {
    border-radius: 5px;
    .error--text {
      color: rgb(255, 0, 0) !important;
    }
  }
  .actions {
    padding: 10px;
  }
}
</style>