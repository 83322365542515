<template>
  <div class="orgas">
    <v-card class="card">
      <v-card-title>Organisations-Einstellungen</v-card-title>
      <v-card-subtitle
        >Hier kannst du die Daten deiner Organisation einsehen</v-card-subtitle
      >
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-card class="card-in-card">
              <v-card-title>{{ selectedOrga.orga.name }}</v-card-title>
              <v-card-subtitle> Name </v-card-subtitle>

              <!-- Daten bearbeiten -->
              <v-card-text v-if="change === true">
                <v-form @submit.prevent="changeName" lazy-validation>
                  <v-row>
                    <v-col cols="8" md="4">
                      <div
                        class="form-group"
                        :class="{ 'form-group--error': $v.name.$error }"
                      >
                        <v-text-field
                          v-model="name"
                          v-bind:label="selectedOrga.orga.name"
                          :error-messages="nameError"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="8" md="4">
                      <v-btn type="submit" :loading="loadingName">
                        Speichern
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="card-in-card">
              <v-card-title>{{ selectedOrga.orga.party }}</v-card-title>
              <v-card-subtitle>Partei</v-card-subtitle>

              <!-- Daten bearbeiten -->
              <v-card-text v-if="change === true">
                <v-form @submit.prevent="changeParty" lazy-validation>
                  <v-row>
                    <v-col cols="8" md="4">
                      <div
                        class="form-group"
                        :class="{ 'form-group--error': $v.party.$error }"
                      >
                        <v-text-field
                          v-model="party"
                          v-bind:label="selectedOrga.orga.party"
                          :error-messages="partyError"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="8" md="4">
                      <v-btn type="submit" :loading="loadingParty">
                        Speichern
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="card-in-card">
              <v-card-title>{{ selectedOrga.orga.location }}</v-card-title>
              <v-card-subtitle>Ort</v-card-subtitle>

              <!-- Daten bearbeiten -->
              <v-card-text v-if="change === true">
                <v-form @submit.prevent="changeLocation" lazy-validation>
                  <v-row>
                    <v-col cols="8" md="4">
                      <div
                        class="form-group"
                        :class="{ 'form-group--error': $v.location.$error }"
                      >
                        <v-text-field
                          v-model="location"
                          v-bind:label="selectedOrga.orga.location"
                          :error-messages="locationError"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <v-col cols="8" md="4">
                      <v-btn type="submit" :loading="loadingLocation">
                        Speichern
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
          <v-card-actions>
            <v-row class="actions">
              <v-btn text @click="changeData"> Daten bearbeiten </v-btn>
              <!-- Orga Löschen aktuell deaktiviert
              <v-btn text v-if="user.nickname === selectedOrga.orga.creator" @click="deleteOrga">
                Organisation Löschen
              </v-btn>-->
            </v-row>
          </v-card-actions>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "OrgaSettings",
  data() {
    return {
      change: false,
      name: "",
      party: "",
      location: "",
      loadingName: false,
      loadingParty: false,
      loadingLocation: false,
    };
  },
  validations: {
    name: {
      required,
    },
    party: {
      required,
    },
    location: {
      required,
    },
  },
  computed: {
    selectedOrga() {
      return this.$store.getters["orga/selectedOrga"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    nameError() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Bitte gib einen Namen an");
      return errors;
    },
    partyError() {
      const errors = [];
      if (!this.$v.party.$dirty) return errors;
      !this.$v.party.required && errors.push("Bitte gib eine Partei an");
      return errors;
    },
    locationError() {
      const errors = [];
      if (!this.$v.location.$dirty) return errors;
      !this.$v.location.required && errors.push("Bitte gib einen Ort an");
      return errors;
    },
  },
  methods: {
    changeData() {
      if (this.user.nickname == this.selectedOrga.orga.creator) {
        this.change = !this.change;
      } else {
        this.$swal({
          title: "Fehler",
          icon: "error",
          text: "Du musst Gründer der Organisation sein um die Daten zu ändern!",
          customClass: {
            confirmButton: "btn btn-confirm",
            cancelButton: "btn btn-cancel",
          },
          buttonsStyling: false,
          confirmButtonText: "Ok",
        });
      }
    },
    async changeName() {
      this.$v.name.$touch();
      if (!this.$v.name.$invalid) {
        this.loadingName = true;
        let orga = Object.assign({}, this.selectedOrga.orga);
        orga.name = this.name;
        this.$store.dispatch("orga/update", orga).then((res) => {
          if (res == -1) {
            this.$swal({
              title: "Es ist ein Fehler aufgetreten",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-confirm",
              },
              confirmButtonText: "OK",
              buttonsStyling: false,
            });
          }
        });
      }
      this.loadingName = false;
    },
    async changeParty() {
      this.$v.party.$touch();
      if (!this.$v.party.$invalid) {
        this.loadingParty = true;
        let orga = Object.assign({}, this.selectedOrga.orga);
        orga.party = this.party;
        this.$store.dispatch("orga/update", orga).then((res) => {
          if (res == -1) {
            this.$swal({
              title: "Es ist ein Fehler aufgetreten",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-confirm",
              },
              confirmButtonText: "OK",
              buttonsStyling: false,
            });
          }
        });
      }
      this.loadingParty = false;
    },
    async changeLocation() {
      this.$v.location.$touch();
      if (!this.$v.location.$invalid) {
        this.loadingLocation = true;
        let orga = Object.assign({}, this.selectedOrga.orga);
        orga.location = this.location;
        this.$store.dispatch("orga/update", orga).then((res) => {
          if (res == -1) {
            this.$swal({
              title: "Es ist ein Fehler aufgetreten",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-confirm",
              },
              confirmButtonText: "OK",
              buttonsStyling: false,
            });
          }
        });
      }
      this.loadingLocation = false;
    },
    async deleteOrga() {
      this.$swal({
        title: "Möchtest du die Organisation wirklich löschen?",
        icon: "warning",
        input: "text",
        inputLabel: "Bitte gib den Namen der Organisation ein:",
        showCancelButton: true,
        confirmButtonText: "Löschen",
        customClass: {
          confirmButton: "btn btn-confirm",
          cancelButton: "btn btn-cancel",
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        inputValidator: (input) => {
          return new Promise((resolve) => {
            if (input === this.selectedOrga.orga.name) {
              resolve();
            } else {
              resolve("Du hast einen falschen Namen eingegeben!");
            }
          });
        },
        preConfirm: () => {
          return this.$store
            .dispatch("orga/deleteOrga", this.selectedOrga.orga)
            .then((res) => {
              if (res == -1) {
                this.$swal.showValidationMessage(
                  "Es ist ein Fehler aufgetreten"
                );
              }
            });
        },
      }).then((res) => {
        if (res.isConfirmed) {
          this.$swal({
            title: "Die Organisation wurde gelöscht",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  text-align: left;
  .selOrga {
    font-weight: 650;
  }
  .card-in-card {
    border-radius: 5px;
    .error--text {
      color: rgb(255, 0, 0) !important;
    }
  }
  .actions {
    padding: 10px;
  }
}
</style>