<template>
  <div class="campaigns">
    <v-card class="card">
      <v-card-title>Benutzerverwaltung</v-card-title>
      <v-card-subtitle
        >Hier kannst du die Nutzer deiner Kampagne einsehen</v-card-subtitle
      >
      <v-card-text>
        Aktuell ausgewählte Kampagne:
        <span class="selCampaign">{{ selectedCampaign.campaign.name }}</span>
        <br />
        Ihre aktuelle Rolle in der Kampagne:
        <span class="selCampaign">{{ userRole }}</span> <br />
        Aktuelle Nutzer:
        <span class="selCampaign"> {{ campaignMember.length }}</span> von max.
        <span class="selCampaign"> {{ campaignInfo.maxUsers }}</span>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
              text
              @click="leaveCampaign"
              v-if="user.nickname != selectedCampaign.campaign.manager"
            >
              Kampagne Verlassen
            </v-btn>
            <v-btn text @click="inviteUser"> Nutzer einladen </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-expansion-panels flat>
              <v-expansion-panel
                v-for="member in campaignMemberWoUser"
                :key="member.id"
              >
                <v-expansion-panel-header class="panel-header">
                  <span>{{ member.nickname }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="panel-content">Rolle: {{ member.role }}</div>
                  <div
                    v-if="
                      member.role !== 'creator' &&
                      (userRole === 'Administrator' || userRole === 'Creator')
                    "
                  >
                    <v-btn text>
                      <div
                        v-if="member.role === 'member'"
                        @click="promoteMember(true, member)"
                      >
                        Status zu Admin ändern
                      </div>
                      <div
                        v-if="member.role === 'admin'"
                        @click="promoteMember(false, member)"
                      >
                        Status zu Member ändern
                      </div>
                    </v-btn>
                    <v-btn text>
                      <v-icon @click="deleteUser(member)">
                        mdi-delete-forever
                      </v-icon>
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "CampaignUser",
  data() {
    return {};
  },
  computed: {
    campaignInfo() {
      return this.$store.getters["orga/campaign/campaignInfo"];
    },
    campaignMember() {
      return this.$store.getters["orga/campaign/campaignMember"];
    },
    campaignMemberWoUser() {
      return this.$store.getters["orga/campaign/campaignMember"].filter(
        (member) => member.id != this.user.id
      );
    },
    selectedCampaign() {
      return this.$store.getters["orga/campaign/selectedCampaign"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    userRole() {
      let role;
      this.campaignMember.forEach((userInCampaign) => {
        if (this.user.id === userInCampaign.id) {
          if (userInCampaign.role === "member") role = "Nutzer";
          if (userInCampaign.role === "admin") role = "Administrator";
          if (userInCampaign.role === "creator") role = "Creator";
        }
      });
      return role;
    },
  },
  methods: {
    leaveCampaign() {
      this.$swal({
        title: "Kampagne wirklich verlassen?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-confirm",
          cancelButton: "btn btn-cancel",
        },
        buttonsStyling: false,
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.$store
            .dispatch(
              "orga/campaign/leaveCampaign",
              this.selectedCampaign.campaign
            )
            .then((res) => {
              if (res == -1) {
                this.$swal.showValidationMessage(
                  "Es ist ein Fehler aufgetreten"
                );
              }
            });
        },
      }).then((res) => {
        if (res.isConfirmed) {
          this.$swal({
            title: "Die Kampagne wurde verlassen",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
        }
      });
    },
    promoteMember(toAdmin, member) {
      let title;
      let memberUpdate;
      toAdmin
        ? (title = "Rolle des Nutzers zu Admin ändern?")
        : (title = "Rolle des Nutzers zu Nutzer ändern?");
      this.$swal({
        title: title,
        icon: "question",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-confirm",
          cancelButton: "btn btn-cancel",
        },
        buttonsStyling: false,
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (toAdmin) {
            memberUpdate = Object.assign({}, member);
            memberUpdate.role = "admin";
          } else {
            memberUpdate = Object.assign({}, member);
            memberUpdate.role = "member";
          }
          return this.$store
            .dispatch("orga/campaign/updateMember", memberUpdate)
            .then((res) => {
              if (res == -1) {
                this.$swal.showValidationMessage(
                  "Es ist ein Fehler aufgetreten"
                );
              }
            });
        },
      }).then((res) => {
        if (res.isConfirmed) {
          let title;
          toAdmin
            ? (title = "Rolle zu erfolgreich zu Admin gewechselt")
            : (title = "Rolle erfolgreich zu Nutzer gewechselt");
          this.$swal({
            title: title,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
        }
      });
    },
    inviteUser() {
      if (this.campaignMember.length < this.campaignInfo.maxUsers) {
        this.$swal({
          title: "Nutzer Einladen",
          input: "text",
          text: "Der Nutzer wird auch automatisch der Organisation hinzugefügt",
          inputLabel: "Bitte gebe den Nutzernamen des Nutzers ein:",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-confirm",
            cancelButton: "btn btn-cancel",
          },
          buttonsStyling: false,
          confirmButtonText: "Hinzufügen",
          cancelButtonText: "Abbrechen",
          showLoaderOnConfirm: true,
          inputValidator: (input) => {
            return new Promise((resolve) => {
              if (input === "") {
                resolve("Bitte gib einen Nutzernamen ein");
              } else {
                resolve();
              }
            });
          },
          preConfirm: (input) => {
            return this.$store
              .dispatch("orga/campaign/inviteUser", input)
              .then((res) => {
                if (res == -1) {
                  this.$swal.showValidationMessage(
                    "Es ist ein Fehler aufgetreten"
                  );
                }
              });
          },
        }).then((res) => {
          if (res.isConfirmed) {
            this.$swal({
              title: "Der Nutzer wurde erfolgreich hinzugefügt",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-confirm",
              },
              confirmButtonText: "OK",
              buttonsStyling: false,
            });
          }
        });
      } else {
        this.$swal({
          title:
            "Die maximale Nutzeranzahl für diese Kampagne wurde bereits erreicht",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-confirm",
          },
          confirmButtonText: "OK",
          buttonsStyling: false,
        });
      }
    },
    deleteUser(member) {
      this.$swal({
        title: `Willst du den Nutzer @${member.nickname} wirklich aus der Kampagne entfernen?`,
        icon: "question",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-confirm",
          cancelButton: "btn btn-cancel",
        },
        buttonsStyling: false,
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.$store
            .dispatch("orga/campaign/deleteUser", member)
            .then((res) => {
              if (res == -1) {
                this.$swal.showValidationMessage(
                  "Es ist ein Fehler aufgetreten"
                );
              }
            });
        },
      }).then((res) => {
        if (res.isConfirmed) {
          this.$swal({
            title: "Der Nutzer wurde erfolgreich gelöscht",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText:"OK",
            buttonsStyling: false,
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  text-align: left;
  .selCampaign {
    font-weight: 650;
  }
  .panel-header {
    text-align: left;
  }
  .panel-content {
    padding-left: 10px;
    padding-bottom: 10px;
  }
}
</style>