<template>
  <div class="orgas">
    <v-card class="card">
      <v-card-title>Organisationen</v-card-title>
      <v-card-subtitle
        >Hier kannst du deine Organisationen einsehen</v-card-subtitle
      >
      <v-card-text v-if="userOrgas.length > 0">
        Aktuell ausgewählte Organisation:
        <span class="text-big">{{ selectedOrga.orga.name }}</span>
      </v-card-text>
      <v-card-text v-if="userOrgas.length <= 0">
        Du bist in keiner Organisation. <br />
        Gründe eine Organisation oder lasse dich über deinen nickname
        <span class="text-big"> @{{ user.nickname }} </span> in eine
        Organisation einladen.
      </v-card-text>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-list>
              <v-list-item-group>
                <v-list-item
                  v-for="orga in userOrgas"
                  :key="orga.id"
                  @click.prevent="selectOrga(orga)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="orga.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-card-actions>
            <v-btn text to="/createOrga"> Organisation erstellen </v-btn>
          </v-card-actions>
          <v-col cols="12">
            <v-card class="card-in-card"> </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "Organizations",
  data() {
    return {};
  },
  computed: {
    userOrgas() {
      return this.$store.getters["orga/userOrgas"];
    },
    selectedOrga() {
      return this.$store.getters["orga/selectedOrga"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    selectOrga(orga) {
      if (orga.id !== this.selectedOrga.orga.id) {
        this.$swal({
          title: "Willst du die Organisation wirklich wechseln?",
          text: "Du kannst jederzeit zurückwechseln",
          icon: "question",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-confirm",
            cancelButton: "btn btn-cancel",
          },
          buttonsStyling: false,
          confirmButtonText: "Ja",
          cancelButtonText: "Nein",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            try {
              this.$store.commit("orga/resetSelOrgaListener");
              this.$store.commit("orga/resetUserOrgasListener");
              this.$store.commit("orga/resetOrgaMemberListener");
              this.$store.commit("orga/campaign/resetUserCampaignsListener");
              this.$store.commit("orga/campaign/resetSelCampaignListener");
              this.$store.commit("orga/campaign/resetCampaignMemberListener");
              this.$store.commit("orga/campaign/resetCampaignInfoListener");
              this.$store.commit("orga/campaign/resetPlakatListener");
            } catch (e) {
              console.error("Error selected Orga Home-View");
            }
            this.$store.commit("orga/campaign/setSelectedCampaign", {
              orga: false,
              campaign: false,
            });
            this.$store.dispatch("orga/initGetSelectedOrga", orga);
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: "Die Organisation wurde gewechselt",
              icon: "success",
              customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText:"OK",
            buttonsStyling: false,
            });
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  text-align: left;
  .text-big {
    font-weight: 650;
  }
  .card-in-card {
    border-radius: 5px;
    .error--text {
      color: rgb(255, 0, 0) !important;
    }
  }
}
</style>