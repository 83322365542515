<template>
  <div class="orgas">
    <v-card class="card">
      <v-card-title>Benutzerverwaltung</v-card-title>
      <v-card-subtitle
        >Hier kannst du die Nutzer deiner Organisation einsehen</v-card-subtitle
      >
      <v-card-text>
        Aktuell ausgewählte Organisation:
        <span class="selOrga">{{ selectedOrga.orga.name }}</span> <br />
        Ihre aktuelle Rolle in der Organisation:
        <span class="selOrga">{{ userRole }}</span>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
              text
              @click="leaveOrga"
              v-if="user.nickname != selectedOrga.orga.creator"
            >
              Organisation Verlassen
            </v-btn>
            <v-btn
              text
              v-if="userRole === 'Creator' || userRole === 'Administrator'"
              @click="inviteUser"
            >
              Nutzer einladen
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-expansion-panels flat>
              <v-expansion-panel
                v-for="member in orgaMemberWoUser"
                :key="member.id"
              >
                <v-expansion-panel-header class="panel-header">
                  <span>{{ member.nickname }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="panel-content">Rolle: {{ member.role }}</div>
                  <div
                    v-if="
                      member.role !== 'creator' ||
                      userRole === 'Administrator' ||
                      userRole === 'Creator'
                    "
                  >
                    <v-btn text>
                      <div
                        v-if="member.role === 'member'"
                        @click="promoteMember(true, member)"
                      >
                        Status zu Admin ändern
                      </div>
                      <div
                        v-if="member.role === 'admin'"
                        @click="promoteMember(false, member)"
                      >
                        Status zu Member ändern
                      </div>
                    </v-btn>
                    <v-btn text>
                      <v-icon @click="deleteUser(member)">
                        mdi-delete-forever
                      </v-icon>
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "OrgaUser",
  data() {
    return {};
  },
  computed: {
    orgaMember() {
      return this.$store.getters["orga/orgaMember"];
    },
    orgaMemberWoUser() {
      return this.$store.getters["orga/orgaMember"].filter(
        (member) => member.id != this.user.id
      );
    },
    selectedOrga() {
      return this.$store.getters["orga/selectedOrga"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    userRole() {
      let role;
      this.orgaMember.forEach((userInOrga) => {
        if (this.user.id === userInOrga.id) {
          if (userInOrga.role === "member") role = "Nutzer";
          if (userInOrga.role === "admin") role = "Administrator";
          if (userInOrga.role === "creator") role = "Creator";
        }
      });
      return role;
    },
  },
  methods: {
    leaveOrga() {
      this.$swal({
        title: "Organisation wirklich verlassen?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-confirm',
          cancelButton: 'btn btn-cancel'
        },
        buttonsStyling: false,
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        preConfirm: () => {
          return this.$store
            .dispatch("orga/leaveOrga", this.selectedOrga.orga)
            .then((res) => {
              if (res == -1) {
                this.$swal.showValidationMessage(
                  "Es ist ein Fehler aufgetreten"
                );
              }
            });
        },
      }).then((res) => {
        if (res.isConfirmed) {
          this.$swal({
            title: "Die Organisation wurde verlassen",
            icon: "success",
                        customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText:"OK",
            buttonsStyling: false,
          });
        }
      });
    },
    promoteMember(toAdmin, member) {
      let title;
      let memberUpdate;
      toAdmin
        ? (title = "Rolle des Nutzers zu Admin ändern?")
        : (title = "Rolle des Nutzers zu Nutzer ändern?");
      this.$swal({
        title: title,
        icon: "question",
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-confirm',
          cancelButton: 'btn btn-cancel'
        },
        buttonsStyling: false,
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (toAdmin) {
            memberUpdate = Object.assign({}, member);
            memberUpdate.role = "admin";
          } else {
            memberUpdate = Object.assign({}, member);
            memberUpdate.role = "member";
          }
          return this.$store
            .dispatch("orga/updateMember", memberUpdate)
            .then((res) => {
              if (res == -1) {
                this.$swal.showValidationMessage(
                  "Es ist ein Fehler aufgetreten"
                );
              }
            });
        },
      }).then((res) => {
        if (res.isConfirmed) {
          let title;
          toAdmin
            ? (title = "Rolle zu erfolgreich zu Admin gewechselt")
            : (title = "Rolle erfolgreich zu Nutzer gewechselt");
          this.$swal({
            title: title,
            icon: "success",
                        customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText:"OK",
            buttonsStyling: false,
          });
        }
      });
    },
    inviteUser() {
      this.$swal({
        title: "Nutzer Einladen",
        input: "text",
        inputLabel: "Bitte gebe den Nutzernamen des Nutzers ein:",
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-confirm',
          cancelButton: 'btn btn-cancel'
        },
        buttonsStyling: false,
        confirmButtonText: "Hinzufügen",
        cancelButtonText: "Abbrechen",
        showLoaderOnConfirm: true,
        inputValidator: (input) => {
          return new Promise((resolve) => {
            if (input === "") {
              resolve("Bitte gib einen Nutzernamen ein");
            } else {
              resolve();
            }
          });
        },
        preConfirm: (input) => {
          return this.$store.dispatch("orga/inviteUser", input).then((res) => {
            if (res == -1) {
              this.$swal.showValidationMessage("Es ist ein Fehler aufgetreten");
            }
          });
        },
      }).then((res) => {
        if (res.isConfirmed) {
          this.$swal({
            title: "Der Nutzer wurde erfolgreich hinzugefügt",
            icon: "success",
                        customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText:"OK",
            buttonsStyling: false,
          });
        }
      });
    },
    deleteUser(member) {
      this.$swal({
        title: `Willst du den Nutzer @${member.nickname} wirklich aus der Organisation entfernen?`,
        icon: "question",
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-confirm',
          cancelButton: 'btn btn-cancel'
        },
        buttonsStyling: false,
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.$store.dispatch("orga/deleteUser", member).then((res) => {
            if (res == -1) {
              this.$swal.showValidationMessage("Es ist ein Fehler aufgetreten");
            }
          });
        },
      }).then((res) => {
        if (res.isConfirmed) {
          this.$swal({
            title: "Der Nutzer wurde erfolgreich gelöscht",
            icon: "success",
                        customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText:"OK",
            buttonsStyling: false,
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  text-align: left;
  .selOrga {
    font-weight: 650;
  }
  .panel-header {
    text-align: left;
  }
  .panel-content {
    padding-left: 10px;
    padding-bottom: 10px;
  }
}
</style>