<template>
  <div class="active-plakat" :class="{ expanded: expanded }">
    <div class="header">
      <div class="location">
        <h3>{{ plakat.address.street + " " + plakat.address.number }}</h3>
        <h3>{{ plakat.address.zip + " " + plakat.address.city }}</h3>
        <h3>{{ plakat.description }}</h3>
        <h3>aktueller Status: {{ plakat.status }}</h3>
      </div>
      <img :src="plakat.url" :class="{ expanded: expanded }" />
    </div>
    <h4 v-on:click="close">+</h4>
    <v-btn text class="expand" @click="expand">
      {{ this.expanded ? "Weniger" : "Mehr" }} anzeigen
    </v-btn>
    <div v-if="expanded" class="more">
      <v-btn
        text
        v-if="plakat.status === 'Geplant'"
        @click="updatePlakat('Aufgehängt')"
      >
        Aufhängen
      </v-btn>
      <v-btn
        text
        v-if="plakat.status === 'Beschädigt'"
        @click="updatePlakat('Aufgehängt')"
      >
        Schaden beheben
      </v-btn>
      <v-btn
        text
        v-if="plakat.status !== 'Abgehängt' && plakat.status !== 'Geplant'"
        @click="updatePlakat('Abgehängt')"
      >
        Abhängen
      </v-btn>
      <v-btn
        text
        v-if="plakat.status !== 'Abgehängt' && plakat.status !== 'Beschädigt'"
        @click="updatePlakat('Beschädigt')"
      >
        Schaden melden
      </v-btn>
      <v-btn text @click="deletePlakat"> Löschen </v-btn>
    </div>
    <div v-if="expanded">
      <v-stepper vertical class="stepper" v-model="len">
        <div v-for="(step, index) in plakat.history.length" :key="index">
          <v-stepper-step :step="index" editable class="header">
            <span class="text">{{ plakat.history[index].currStatus }}</span>
          </v-stepper-step>
          <v-stepper-content :step="index" class="stepperContent">
            Zeitpunkt:
            {{
              plakat.history[index].timestamp.toDate().toLocaleString("de-DE")
            }}
            <br />
            Bearbeiter: {{ plakat.history[index].username }}
            <div v-if="plakat.history[index].comment !== ''">
              <br />
              Kommentar: {{ plakat.history[index].comment }}
            </div>
          </v-stepper-content>
        </div>
      </v-stepper>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivePlakat",
  data() {
    return {
      expanded: false,
    };
  },
  props: ["plakat"],
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    len: {
      get: function () {
        return this.plakat.history.length;
      },
      set: function (newVal) {
        return newVal;
      },
    },
  },
  methods: {
    expand() {
      this.expanded = !this.expanded;
    },
    close() {
      this.$emit("close", null);
    },
    async deletePlakat() {
      this.$swal({
        title: "Möchtest du das Plakat wirklich löschen?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-confirm",
          cancelButton: "btn btn-cancel",
        },
        buttonsStyling: false,
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.$store
            .dispatch("orga/campaign/deletePlakat", this.plakat)
            .then((res) => {
              if (res !== 1) {
                this.$swal.showValidationMessage(
                  "Es ist ein Fehler aufgetreten"
                );
              }
            });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit("close", null);
          this.$swal({
            title: "Das Plakat wurde erfolgreich gelöscht",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
        }
      });
    },
    async updatePlakat(status) {
      let updatedPlakat = Object.assign({}, this.plakat);
      let title = "";
      let icon = "";
      let sucTitle = "";
      if (status === "Aufgehängt") {
        title = "Möchtest du das Plakat aufhängen?";
        icon = "question";
        sucTitle = "Das Plakat wurde erfolgreich aufgehängt";
      } else if (status === "Abgehängt") {
        title = "Möchtest du das Plakat wirklich abhängen?";
        icon = "warning";
        sucTitle = "Das Plakat wurde erfolgreich abgehängt";
      } else if (status === "Beschädigt") {
        title = "Möchtest du einen Schaden melden?";
        icon = "warning";
        sucTitle = 'Das Plakat wurde mit dem Status "Beschädigt" versehen';
      }
      this.$swal({
        title: title,
        icon: icon,
        input: "textarea",
        inputPlaceholder: "Kommentar hinzufügen",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-confirm",
          cancelButton: "btn btn-cancel",
        },
        buttonsStyling: false,
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        showLoaderOnConfirm: true,
        inputValidator: (input) => {
          return new Promise((resolve) => {
            if (input.length >= 50) {
              resolve("Mehr als 50 Zeichen sind nicht erlaubt.");
            } else {
              resolve();
            }
          });
        },
        preConfirm: (input) => {
          updatedPlakat.status = status;
          updatedPlakat.history.push({
            currStatus: status,
            prevStatus: this.plakat.status,
            timestamp: new Date(),
            uid: this.user.id,
            username: this.user.nickname,
            comment: input,
          });
          return this.$store
            .dispatch("orga/campaign/updatePlakat", updatedPlakat)
            .then((res) => {
              if (res !== 1) {
                this.$swal.showValidationMessage(
                  "Es ist ein Fehler aufgetreten"
                );
              }
            });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.$emit("close", null);
          this.$swal({
            title: sucTitle,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText: "OK",
            buttonsStyling: false,
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.active-plakat {
  padding: 10px;
  position: absolute;
  z-index: 1000;
  bottom: 50px;
  left: 0;
  right: 20vw;
  margin-left: auto;
  margin-right: auto;
  width: 40vw;
  height: 200px;
  background: white;
  border-radius: 5px;
  box-shadow: 5px 5px 9px 0px rgba(0, 0, 0, 0.27);
  opacity: 1;
  transition: width linear 0.25s, height 0.25s ease-in-out;
  &.expanded {
    height: calc(100vh - 180px);
    transition: width linear 0.25s, height 0.25s ease-in-out;
    width: 55vw;
  }
  h4 {
    position: absolute;
    right: 10px;
    top: 5px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    font-size: 1.5rem;
    transform: rotate(45deg); 
  }
  img {
    position: absolute;
    top: 25px;
    left: 25px;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    &.expanded {
      transition: all 0.1s ease-out;
      height: 80px;
      width: 80px;
    }
  }
  .expand {
    margin: 0.5rem;
    background: transparent;
    font-size: 1.5rem;
    &:hover {
      background-color: #cccccc;
      transition: all 0.1s ease-out;
    }
  }
  .more {
    margin-top: 5px;
    &:hover {
      transition: all 0.1s ease-out;
    }
  }
  .stepper {
    overflow-y: auto;
    border-radius: 5px;
    margin: 5px;
    &:hover {
      transition: all 0.1s ease-out;
    }
    .stepperContent {
      text-align: left;
    }
    .header {
      background: rgb(255, 188, 143);
      background: linear-gradient(
        315deg,
        rgba(255, 188, 143, 1) 0%,
        rgba(255, 143, 158, 1) 100%;
      );
      .text {
        padding-left: 5px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .active-plakat {
    width: 100%;
    .header{
      display: grid;
      grid-template-columns: 1fr 2fr;
      .location{
        grid-column: 2;
      }
      img{
        grid-column: 1;
        margin: 1rem;
        left:0;
        top:0;
      }
    }
    &.expanded {
      width: 100%;
      height: calc(100% - 120px);
      top: 80px;
      .header {
        display: grid;
        grid-template-columns: 1fr 2fr;
        .location {
          padding-top: 1rem;
          padding-bottom: 2rem;
          font-size: 0.75rem;
          grid-column:2;
        }
        img {
          margin:1rem;
          grid-column: 1;
          top:0;
          left:0;
        }
      }
    }
    .expand {
      position: absolute;
      bottom: 10px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
}
</style>