<template>
  <div class="map-wrap">
    <div id="map" class="content"></div>

    <v-btn class="btn-left planning" @click="plakatPlanning" v-if="!planning">
      Plakate planen
    </v-btn>
    <v-btn class="btn-left planning" @click="stopPlanning" v-else>
      Sichern
    </v-btn>
    <v-btn class="btn-left cancel" @click="cancelPlanning" v-if="planning">
      Abbrechen
    </v-btn>
    <v-btn class="toggle" @click="map_list_toggle = true">
      <v-icon> mdi-format-list-bulleted-square </v-icon>
    </v-btn>

    <list-view :class="{active : map_list_toggle}" @filter="updateFilter" @selected="selectPlakat" v-on:toggle="map_list_toggle = false"/>
    <active-plakat
      v-if="selected != null"
      v-bind:plakat="selected"
      @close="selectPlakat"
    />
    <planned-plakat
      v-if="selectedPlanned.key != null"
      v-bind:plannedPlakat="selectedPlanned"
      @close="selectedPlannedPlakat"
      @delete="deletePlannedPlakat"
      @description="descriptionPlannedPlakat"
    />
    <v-container class="tooltip" v-if="planning">
      Du kannst nun durch einen einfachen Klick ein Plakat planen! <br/> Aktuell:
      {{ sumPlakate }} <br/> Max.: {{ campaignInfo.maxPlakate }}
    </v-container>
  </div>
</template>
<script>
import MarkerClusterer from "@googlemaps/markerclustererplus";

import { storage } from "@/utils/firebase";
import gmapsInit from "@/utils/gmaps";

import ListView from "@/components/map/ListView";
import ActivePlakat from "@/components/map/ActivePlakat";
import PlannedPlakat from "@/components/map/PlannedPlakat.vue";

export default {
  components: {
    ListView,
    ActivePlakat,
    PlannedPlakat,
  },
  data() {
    return {
      google: null,
      geocoder: null,

      markers: [],
      markerPlan: [],
      markerAuf: [],
      markerBesch: [],
      markerAbg: [],

      markerCluster: null,
      markerClusterPlan: null,
      markerClusterAuf: null,
      markerClusterBesch: null,
      markerClusterAbg: null,

      map: null,
      filter: "",
      selected: null,

      planning: false,
      plannedPlakateSum: 0,
      plannedMarkers: null,
      plannedPlakate: null,
      plannedPlakateKey: 0,
      plannedMarkerCluster: null,
      planningListener: null,
      selectedPlanned: { key: null, plakat: null },

      map_list_toggle: false,
      map_centered: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.planning) {
      this.$swal({
        title: "Möchtest du die Seite wirklich verlassen?",
        text: "Die geplanten Plakate gehen verloren",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-confirm",
          cancelButton: "btn btn-cancel",
        },
        buttonsStyling: false,
        confirmButtonText: "Verlassen",
        cancelButtonText: "Abbrechen",
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  computed: {
    getPlakate: function () {
      return this.$store.getters["orga/campaign/plakate"].filter((plakat) =>
        this.searchFilter(plakat, this.filter)
      );
    },
    user() {
      return this.$store.getters["user/user"];
    },
    campaignInfo: function () {
      return this.$store.getters["orga/campaign/campaignInfo"];
    },
    sumPlakate() {
      return (
        this.campaignInfo.plakateGeplant +
        this.campaignInfo.plakateAufgehaengt +
        this.campaignInfo.plakateBeschaedigt +
        this.campaignInfo.plakateAbgehaengt +
        this.plannedPlakateSum
      );
    },
  },
  watch: {
    getPlakate: function () {
      this.createMarkers();
    },
  },
  async mounted() {
    try {
      this.google = await gmapsInit();
      this.map = new this.google.maps.Map(document.getElementById("map"),{
      });
      let one = new this.google.maps.LatLng(49.47, 8.45);
      let two = new this.google.maps.LatLng(49.49, 8.47);
      this.map.setZoom(10);
      this.map.fitBounds(new this.google.maps.LatLngBounds(one, two));
      this.map.setOptions({ draggableCursor: "default" });
      this.createMarkers();
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    updateFilter(newFilter) {
      this.filter = newFilter;
      this.createMarkers();
    },
    async selectPlakat(plakat) {
      if (plakat !== null && plakat.url === undefined) {
        try {
          let url = await storage.ref("images/" + plakat.id).getDownloadURL();
          plakat.url = url;
        } catch (e) {
          plakat.url = "/images/defaultplakat.jpg";
        }
      }
      this.selected = plakat;
    },
    searchFilter(plakat, filter) {
      let full = plakat.address.fullName + plakat.description + plakat.status;
      return full.toLowerCase().includes(filter.toLowerCase());
    },
    createMarkers() {
      this.markers = [];
      let size = { width: 50, height: 50 };
      let icon0 = {
        url: require("../assets/plakat_white.png"),
        scaledSize: size,
      };
      let icon1 = {
        url: require("../assets/plakat.png"),
        scaledSize: size,
      };
      let icon2 = {
        url: require("../assets/plakat_grau.png"),
        scaledSize: size,
      };
      let icon3 = {
        url: require("../assets/plakat_rot.png"),
        scaledSize: size,
      };
      for (let plakat of this.getPlakate) {
        let pos = { lat: plakat.latitude, lng: plakat.longitude };

        const marker = new this.google.maps.Marker({
          position: pos,
          title: plakat.description,
          optimized: false,
          icon:
            plakat.status === "Abgehängt"
              ? icon2
              : plakat.status === "Beschädigt"
              ? icon3
              : plakat.status === "Geplant"
              ? icon0
              : icon1,
        });
        marker.addListener("click", () => {
          this.selectPlakat(plakat);
        });

        if (plakat.status === "Geplant") {
          this.markerPlan.push(marker);
        } else if (plakat.status === "Aufgehängt") {
          this.markerAuf.push(marker);
        } else if (plakat.status === "Beschädigt") {
          this.markerBesch.push(marker);
        } else if (plakat.status === "Abgehängt") {
          this.markerAbg.push(marker);
        }
        this.markers.push(marker);
      }
      if (this.markerCluster !== null) {
        this.markerCluster.clearMarkers();
      }
      this.markerCluster = new MarkerClusterer(this.map, this.markers, {
        styles: [
          {
            url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
            textSize: 20,
            //width: 50,
            //height: 44,
            anchorIcon: [0, 0],
            anchorText: [14, 21],
          },
          {
            url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png",
            textSize: 20,
            //width: 50,
            //height: 44,
            anchorIcon: [0, 0],
            anchorText: [16, 17],
          },
          {
            url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png",
            textSize: 20,
            //width: 50,
            //height: 44,
            anchorIcon: [0, 0],
            anchorText: [14, 21],
          },
        ],
      });

      if(!this.map_centered){
        let min_lat = 100.0;
        let min_lng = 100.0;
        let max_lat = 0;
        let max_lng = 0;
        let delta = 0.003;
        for(let plakat of this.getPlakate){
          min_lat = Math.min(min_lat,plakat.latitude);
          min_lng = Math.min(min_lng,plakat.longitude);
          max_lat = Math.max(max_lat,plakat.latitude);
          max_lng = Math.max(max_lng,plakat.longitude);
        }
        if(min_lat == 100.0 && min_lng == 100.0){
          min_lat = 48.0;
          max_lat = 54.0;
          min_lng = 8.0;
          max_lng = 12.0;
        }
    
          min_lat -= delta;
          min_lng -= delta;
          max_lat += delta;
          max_lng += delta;
      let one = {lat:min_lat,lng: min_lng};
      let two = {lat:max_lat,lng: max_lng};


      this.map.fitBounds(new this.google.maps.LatLngBounds(one, two));
      this.map_centered = true;
      }
    },
    plakatPlanning() {
      this.geocoder = new this.google.maps.Geocoder();

      this.planning = true;
      let iconPlanned = {
        url: require("../assets/plakat_white.png"),
        scaledSize: { width: 50, height: 50 },
      };
      this.plannedMarkers = new Map();
      this.plannedPlakate = new Map();
      this.plannedPlakateKey = 0;
      this.plannedPlakatSum = 0;

      this.planningListener = this.map.addListener("click", (e) => {
        // Check ob maxPlakate der Kampagne nicht überschritten wurden
        if (this.sumPlakate < this.campaignInfo.maxPlakate) {
          let today = new Date();
          let key = this.plannedPlakateKey;

          this.geocoder.geocode({ location: e.latLng }, (results, status) => {
            if (status === "OVER_QUERY_LIMIT") {
              this.$swal({
                title:
                  "Du bist etwas zu schnell für uns, bitte warte einen Augenblick",
                icon: "warning",
                            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText:"OK",
            buttonsStyling: false,
              });
            } else if (status === "OK") {
              let address = results[0];
              let newAddress = {
                fullName: address.formatted_address,
                locality: "",
                country: "",
                street_number: "",
                route: "",
                postal_code: "",
              };
              address.address_components.forEach((component) => {
                newAddress[component.types[0]] = component.long_name;
              });
              var newPlakat = {
                address: {
                  city: newAddress.locality,
                  country: newAddress.country,
                  fullName: newAddress.fullName,
                  number: newAddress.street_number,
                  street: newAddress.route,
                  zip: newAddress.postal_code,
                },
                dateAdded: today,
                description: "",
                history: [
                  {
                    currStatus: "Geplant",
                    prevStatus: "Unbekannt",
                    timestamp: today,
                    uid: this.user.id,
                    username: this.user.nickname,
                    comment: "",
                  },
                ],
                imageVersion: 0,
                latitude: e.latLng.lat(),
                longitude: e.latLng.lng(),
                status: "Geplant",
              };

              this.plannedPlakate.set(key, newPlakat);

              const marker = new this.google.maps.Marker({
                position: e.latLng,
                title: newPlakat.description,
                optimized: false,
                icon: iconPlanned,
              });

              marker.addListener("click", () => {
                this.selectedPlannedPlakat(key, newPlakat);
              });

              this.plannedMarkers.set(key, marker);

              if (this.plannedMarkerCluster !== null) {
                this.plannedMarkerCluster.clearMarkers();
              }

              let plannedArray = [];
              for (let marker of this.plannedMarkers.values()) {
                plannedArray.push(marker);
              }
              this.plannedMarkerCluster = new MarkerClusterer(
                this.map,
                plannedArray,
                {
                  styles: [
                    {
                      url: require("../assets/plakat_white.png"),
                      textSize: 24,
                      fontWeight: 700,
                      anchorIcon: [0, 0],
                      //anchorText: [14, 21],
                    },
                  ],
                }
              );
              this.plannedPlakateKey += 1;
              this.plannedPlakateSum += 1;
            }
            // Wenn Geocoding fehlschlägt:
            else {
              this.$swal({
                title:
                  "Wir konnten die Adresse nicht finden, bitte verschiebe das Plakat",
                icon: "warning",
                            customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText:"OK",
            buttonsStyling: false,
              });
            }
          });
        } else {
          this.$swal({
            title:
              "Du hast die maximale Anzahl an Plakaten deiner Kampagne erreicht",
            icon: "warning",
                        customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText:"OK",
            buttonsStyling: false,
          });
        }
      });
    },
    stopPlanning() {
      let laenge = this.plannedMarkers.size;
      if (laenge > 0) {
        this.$swal({
          title: "Möchtest du das Planen der Plakate beenden?",
          text: `Du hast ${laenge} Plakat/e geplant`,
          icon: "question",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-confirm",
            cancelButton: "btn btn-cancel",
          },
          buttonsStyling: false,
          confirmButtonText: "Ja",
          cancelButtonText: "Nein",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            let plannedPlakate = [];
            for (let plakat of this.plannedPlakate.values()) {
              plannedPlakate.push(plakat);
            }
            return this.$store
              .dispatch("orga/campaign/setPlakate", plannedPlakate)
              .then((res) => {
                if (res !== 1) {
                  this.$swal.showValidationMessage(
                    "Es ist ein Fehler aufgetreten"
                  );
                }
              });
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.planning = false;
            this.google.maps.event.removeListener(this.planningListener);
            this.plannedMarkers.forEach((marker) => {
              marker.setMap(null);
            });
            this.plannedMarkerCluster.clearMarkers();
            this.plannedMarkers = [];
            this.plannedPlakate = null;
            this.selectedPlanned.key = null;
            this.selectedPlanned.plakat = null;
            this.geocoder = null;
            this.$swal({
              title: "Du hast erfolgreich deine Plakate geplant",
              icon: "success",
                          customClass: {
              confirmButton: "btn btn-confirm",
            },
            confirmButtonText:"OK",
            buttonsStyling: false,
            });
          }
        });
      } else {
        this.planning = false;
        this.selectedPlanned.key = null;
        this.selectedPlanned.plakat = null;
        this.geocoder = null;
      }
    },
    selectedPlannedPlakat(key, plakat) {
      this.selectedPlanned.plakat = plakat;
      this.selectedPlanned.key = key;
    },
    cancelPlanning() {
      if (this.plannedMarkers.size > 0) {
        this.$swal({
          title: "Möchtest du das Planen der Plakate wirklich abbrechen?",
          text: "Alle geplanten Plakate werden verworfen!",
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-confirm",
            cancelButton: "btn btn-cancel",
          },
          buttonsStyling: false,
          confirmButtonText: "Ja",
          cancelButtonText: "Nein",
        }).then((result) => {
          if (result.isConfirmed) {
            this.planning = false;
            this.google.maps.event.removeListener(this.planningListener);
            this.plannedMarkers.forEach((marker) => {
              marker.setMap(null);
            });
            this.plannedMarkerCluster.clearMarkers();
            this.plannedMarkers = [];
            this.plannedPlakate = null;
            this.selectedPlanned.key = null;
            this.selectedPlanned.plakat = null;
            this.geocoder = null;
          }
        });
      } else {
        this.planning = false;
        this.selectedPlanned.key = null;
        this.selectedPlanned.plakat = null;
        this.geocoder = null;
      }
    },
    deletePlannedPlakat(plannedPlakat) {
      this.plannedPlakate.delete(plannedPlakat.key);
      let marker = this.plannedMarkers.get(plannedPlakat.key);
      this.plannedMarkers.delete(plannedPlakat.key);
      this.plannedMarkerCluster.removeMarker(marker);
      this.plannedPlakateSum -= 1;
      this.selectedPlanned.key = null;
      this.selectedPlanned.plakat = null;
    },
    descriptionPlannedPlakat(plannedPlakat) {
      this.plannedPlakate.set(plannedPlakat.key, plannedPlakat.plakat);
    },
  },
};
</script>

<style lang="less">
#map {
  width: 80vw;
  height: calc(100vh - 60px);
  top: 60px;
}

.btn-left {
  position: absolute;
  top: 120px;
  left: 10px;
  width: 168px;
  background-color: #ffffff !important;
  opacity: 1;
  color: black;
  border-radius: 3px;
}
.toolbar {
  position: absolute;
  top: 120px;
  left: 15rem;
}

.planning {
  top: 120px;
}

.cancel {
  top: 170px;
}

.toggle{
  display:none;
}

.tooltip {
  position: absolute;
  top: 220px;
  left: 10px;
  width: 168px;
  text-align: center;
  align-items: center;
  font-weight: 400;
  font-size: 1.1rem;
  height: auto;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.cluster img {
  width: 50px;
  height: 50px;
  clip: unset;
}
@media screen and (max-width: 768px) {
  #map{
    width: 100%;
  }
  .btn-left {
    position: absolute;
    top: 120px;
    left: 10px;
    width: 150px;
    background-color: #ffffff !important;
    opacity: 1;
    color: black;
    border-radius: 3px;
  }
  .planning {
    top: 120px;
  }
  .cancel {
    top: 170px;
  }
  .sidebar{
    display:none;
    &.active{
      display:block;
      width: 100%;
      height: 100%;
    }
  }
  .tooltip{
    display:none;
  }
  .toggle{
    display:block;
    position:absolute;
    top:70px;
    right:60px;
    border-radius:3px;
    padding: 1.1rem 0;
    background-color: white !important;
  }
}
</style>