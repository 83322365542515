<template>
  <div class="sidebar">
    <div class="searchHeader">Suche</div>
    <v-icon class="toggle" v-on:click="toggleMap()" color="black" >mdi-map-outline</v-icon>
    <div class="header" v-if="getPlakate.length == 1">
        Es wird {{ getPlakate.length }} Plakat angezeigt
        <v-text-field class="searchField" v-model="filter" type="text" />    
    </div>
    <div class="header" v-else>
        Es werden {{ getPlakate.length }} Plakate angezeigt
        <v-text-field class="searchField" v-model="filter" type="text" placeholder="Plakat suchen"/>    
    </div>
    <v-divider />
    <div class="plakate">
      <v-expansion-panels flat>
        <v-expansion-panel
          v-for="plakat in getPlakate"
          :key="plakat.id"
        >
          <v-expansion-panel-header v-on:click="selectPlakat(plakat)">
            <span class="headerExpansion">{{plakat.address.street }}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="plakat">
            {{ plakat.address.street }}<br />
            {{ plakat.address.zip }} {{ plakat.address.city }}<br />
            {{ plakat.description }}<br />
            {{ plakat.status }}
          </v-expansion-panel-content>
          <v-divider />
        </v-expansion-panel>
      </v-expansion-panels>  
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: "",
      waitingFilter: "",
    };
  },
  computed: {
    getPlakate() {
      return this.$store.getters["orga/campaign/plakate"].filter((plakat) =>
        this.searchFilter(plakat, this.waitingFilter)
      );
    },
  },
  methods: {
    searchFilter(plakat, filter) {
      let full = plakat.address.fullName + plakat.description + plakat.status;
      return full.toLowerCase().includes(filter.toLowerCase());
    },
    selectPlakat(plakat) {
      this.$emit("selected", plakat);
    },
    toggleMap(){
      this.$emit('toggle',true);
    }
  },
  watch: {
    filter: function () {
      if (!this.awaitingFilter) {
        setTimeout(() => {
          this.$emit("filter", this.filter);
          this.waitingFilter = this.filter;
          this.awaitingFilter = false;
        }, 1000);
      }
      this.awaitingFilter = true;
    },
  },
};
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.sidebar {
  position: absolute;
  top: 60px;
  right: 0;
  width: 20vw;
  height: calc(100vh - 200px);
  background-color: white;
  .searchHeader {
    font-size: 18px;
    font-weight: 600;
    padding-top: 10px;
  }
  .header {
    //display: flex;
    align-items: center;
    padding: 1rem;
    .searchField {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
  .plakate {
    height: calc(100vh - 180px - 80px);
    overflow-y: scroll;
    .headerExpansion {
        text-align: left;
    }
    .plakat {
      text-align: left;
      .inner {
          padding: 1rem 1rem;
          background-color: #ffffff;
          transition: background-color 0.1s linear;
          transition-delay: 0.1s;
        &:hover {
          background-color: #dddddd;
          transition: background-color 0.1s linear;

        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
.toggle{
  display:none;
}
}
@media screen and (max-width: 768px) {
  .toggle{
    display:inline;
    width: 2rem;
    position:absolute;
    top:20px;
    right:20px;
    padding:0;
  }
}
</style>